<script lang="ts" setup>
const { t } = useT();
const { open, isOpen } = useFunrizeModals();
const localPath = useLocalePath();
const { isDesktop } = useDevice();
const { isFinOperationAvailable } = useCashHelper();
const { openDefault } = usePresetPackageController();
const { tourPoints, entries } = useBalance({ options: { cached: true, immediate: false } });
const isGuest = useIsGuest();
const [isShowStatusSystemInfo, toggleStatusSystemInfo] = useToggle(false);
const { isSweepStakes } = useSwitchMode();
const { isEventTheme } = useEventsCollector();
const { closeModal } = useGameModal();

const logoImg = computed(() => `/nuxt-img/${isEventTheme.value ? "logo-event.webp" : "logo@2x.png"}`);
const isGameModal = computed(() => isOpen("LazyOModalGame"));
const isEmptyBalance = computed(
	() => (isSweepStakes.value && !entries.value) || (!isSweepStakes.value && !tourPoints.value)
);

const handleOpenCash = () => {
	window?.$store?.gaCash?.deposit?.({
		location: "header",
		step: "view_payments_info"
	});
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");

	if (!isFinOperationAvailable.value) {
		return;
	}

	if (isEmptyBalance.value) {
		openDefault();
	}
};

const handleOpenLogin = () => {
	dispatchGAEvent({
		event: "login",
		location: "header",
		step: "start",
		type: ""
	});
	open("LazyOModalLogin");
};

const handleOpenSignup = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "registration",
		location: "header"
	});
	open("LazyOModalSignup", { location: "header" });
};
</script>

<template>
	<AHeader data-tid="header">
		<div v-if="!isGuest && isGameModal && isDesktop" class="row">
			<LazyOStatusSystemAvatar prevent-navigate @click.stop="toggleStatusSystemInfo()" />
			<LazyOSeasonProgress />
			<LazyOStatusSystemInfoPopper v-if="isShowStatusSystemInfo" @close="toggleStatusSystemInfo(false)" />
		</div>
		<NuxtLink v-else :to="localPath('/')">
			<NuxtImg
				:class="['logo', { event: isEventTheme }]"
				data-tid="logo"
				:src="logoImg"
				alt="logo"
				loading="lazy"
				format="avif"
				:width="isEventTheme ? '312' : '156'"
				:height="isEventTheme ? '216' : '96'"
			/>
		</NuxtLink>
		<div v-if="!isGuest" class="row">
			<LazyONotificationCenter />
			<LazyORewards v-if="isDesktop" location-tid="desk" />
			<OHeaderBalance :class="{ 'is-blurred': isGameModal }" />
			<AButton variant="primary" data-tid="header-buy-btn" @click="handleOpenCash">
				<AText variant="toledo" :modifiers="['uppercase', 'bold']">{{ t("Buy") }}</AText>
			</AButton>
			<LazyOStatusSystemAvatar v-if="!isGameModal" data-tid="header-avatar" class="account-status" />
			<AButton v-if="isGameModal" class="close-game" variant="ghost" @click="closeModal">
				<NuxtIcon name="16/close" filled />
			</AButton>
		</div>
		<div v-else class="buttons">
			<template v-if="!isGameModal">
				<AButton class="login-btn" data-tid="header-login-btn" @click="handleOpenLogin">
					<AText variant="toledo" :modifiers="['uppercase', 'bold']">{{ t("Log in") }}</AText>
				</AButton>
				<AAnimationPulse :color="isEventTheme ? '#f04' : 'var(--cairo)'" :width="30" active>
					<AButton
						:class="{ 'btn-decor': isEventTheme }"
						variant="primary"
						data-tid="header-register-btn"
						@click="handleOpenSignup"
					>
						<AText variant="toledo" :modifiers="['uppercase', 'bold']">{{ t("Sign Up") }}</AText>
					</AButton>
				</AAnimationPulse>
			</template>
			<template v-else>
				<AText variant="topeka" class="balance text-coimbatore">
					<NuxtIcon name="16/coins" filled />
					{{ t("Balance") }}
				</AText>
				<AAnimationPulse color="var(--cairo)" :width="30" active>
					<AButton variant="primary" @click="open('LazyOModalSignup')">
						<AText variant="toledo" :modifiers="['uppercase', 'bold']">{{ t("Get coins") }}</AText>
					</AButton>
				</AAnimationPulse>
			</template>
		</div>
	</AHeader>
</template>

<style scoped lang="scss">
header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	padding: 0 gutter(3.5) 0 gutter(4.5);
	border-bottom: 1px solid var(--cordoba);

	@include media-breakpoint-down(md) {
		padding: 0 gutter(2);
	}

	@include media-breakpoint-down(lg) {
		padding: 0 gutter(1);

		&:deep(.notification-center) {
			max-width: 0;

			.badge,
			& > span,
			& > .topeka {
				display: none;
			}
		}
	}

	.row {
		display: flex;
		align-items: center;
		gap: gutter(1);
	}

	.logo {
		width: 78px;
		height: 48px;

		&.event {
			width: 78px;
			height: 54px;
		}
	}
}

.buttons {
	display: inline-flex;
	gap: gutter(4);

	@include media-breakpoint-down(xl) {
		gap: gutter(1);
	}

	.login-btn {
		border-color: transparent;
		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			border-radius: 50px;
			border: 1px solid transparent;
			background: linear-gradient(90deg, var(--chimoio), var(--cucuta)) border-box;
			-webkit-mask:
				linear-gradient(#fff 0 0) padding-box,
				linear-gradient(#fff 0 0);
			mask-composite: exclude;
		}
		&:hover::before {
			opacity: 1;
		}
	}
}

.balance-tabs.is-blurred {
	transition: 0.2s filter 8s ease;
	filter: blur(4px);

	&:hover {
		filter: blur(0);
		transition-delay: 0s;
	}
}

.account-status.avatar-progress {
	margin-left: gutter(2);

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.close-game {
	padding: gutter(1);
	margin-left: gutter(5);
}
.balance {
	width: 93px;
	height: 32px;
	border-radius: 50px;
	background-color: var(--conroe);
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	cursor: pointer;
	position: relative;
	margin-right: gutter(-3);

	@include media-breakpoint-down(xl) {
		margin-right: 0;
	}

	&:before {
		content: "";
		display: block;
		width: 99px;
		height: 38px;
		border-radius: 50px;
		border: 1px solid var(--conroe);
		position: absolute;
		top: -3px;
		left: -3px;
	}
}

.btn-decor {
	&.primary {
		background: #f04;
		border-color: #f04;
		box-shadow: 0px 2px 10px 0px rgba(239, 8, 216, 0.4);

		&:hover {
			background: #f04;
			border-color: #f04;
		}
	}
}
</style>
